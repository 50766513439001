import React from "react"

const WavefoundryLogo = () => {
  return (
    <svg viewBox="0 0 131 74" fill="currentColor" width="100%">
      <path d="M55.03 34.69C69.38 38.57 84.67 37.02 97.92 30.33C103.05 27 107.99 23.39 112.71 19.51C113.87 18.68 114.12 17.07 113.27 15.93C113.18 15.81 113.09 15.71 112.99 15.61C104.99 9.12 94.49 -0.79 68.6 0.97C52.33 1.99 39.35 12.73 35.43 16.32C34.75 16.97 34.69 18.04 35.31 18.76C38.49 22.35 47.94 32.5 55.03 34.69Z" />
      <path d="M114.21 21.19C111.79 22.98 108.19 25.78 104.84 28.17C106.46 29.53 110.87 33.94 111.64 34.57C113.24 35.85 114.93 38.12 117.85 37.8C121.85 37.37 130.82 34.33 130.79 30.3C130.77 26.26 125.55 21.05 122.87 20.27C121.11 20.15 119.34 20.39 117.68 20.97C116.55 21.33 115.36 21.4 114.21 21.19Z" />
      <path d="M67.48 40.15C67.48 40.15 74.34 40 76.32 39.79C83.6 39.22 90.63 36.91 96.82 33.05C83.31 39.07 68.14 40.21 53.89 36.28C46.98 34.06 37.83 24.43 34.11 20.25C33.99 20.39 33.07 21.57 32.95 21.73C31.68 23.26 29.39 23.48 27.85 22.2C27.68 22.06 27.52 21.89 27.38 21.73C22.44 17.76 15.77 16.73 9.87 19.02C2.62 21.99 0.84 30.3 0.79 35.13C0.79 37.54 8.03 44.01 13.3 44.54C19.98 45.33 26.62 42.83 31.14 37.85C32.93 35.99 35.42 34.98 38 35.08C41.6 34.68 45.2 35.82 47.92 38.22C47.3 42.2 42.86 44.52 43.38 48.82C44.18 55.53 51.98 72.19 58.52 73.31C67.62 74.85 91.68 70.52 91.36 62.29C91.31 59.78 84.86 58.94 79.07 57.87C69.73 56.3 66.39 49.37 64.8 44.79C63.98 42.37 66.94 41.07 67.48 40.15Z" />
    </svg>
  )
}

export default WavefoundryLogo;
