import React from 'react'
import { Link } from 'gatsby'

const DropdownMenuLink = ({ to, label, dropdownMenuItems }) => {
    return (
        <li className='menu-link-list-item'>
            <Link 
                className='menu-link'
                to={to} 
                activeClassName="active"
            >
                {label}
            </Link>
            <div className='dropdown-menu'>
                <ul>
                    {dropdownMenuItems.map( ( {dropdownTo, dropdownLabel}, idx) => {
                        return (
                            <li key={`dropdown-item-${idx}`}>
                                <Link to={dropdownTo}>{dropdownLabel}</Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </li>
    )
}

export default DropdownMenuLink