import React from "react";
import { Link } from "gatsby";
import FooterButtons from "./FooterButtons";
import PhoneNumberLink from "./PhoneNumberLink";
import logoWhite from "../images/logo_white.png";
import WavefoundryLogo from "./WavefoundryLogo";

const d = new Date();
const year = d.getFullYear() || 2020;

export default () => {
  return (
    <footer>
      <div className="layout-container">
        <div className="footer-container">
          <div className="footer-section">
            <Link to="/" title="Philips Academy">
              <img alt="Philips Academy Logo" src={logoWhite} />
            </Link>
          </div>
          <div className="footer-section link-section">
            <Link to="/contact/" className="footer-link">
              Contact Us
            </Link>
            <Link to="/donate/" className="footer-link">
              Donate
            </Link>
            <a
              href="https://shops.philipsacademync.org"
              className="footer-link"
            >
              Philips Academy Shops
            </a>
          </div>
          <div className="footer-section">
            <FooterButtons className="dark-bg" />
          </div>
        </div>
        <div className="footer-container caption-section">
          <div>
            <p className="caption">3115 Providence Road Charlotte, NC 28211</p>
            <p className="caption">
              Phone: <PhoneNumberLink phoneNumber={"704.365.4533"} />
            </p>
            <p className="caption">
              Fax: <PhoneNumberLink phoneNumber={"704.365.4508"} />
            </p>
            <p className="caption">
              <a href="mailto:info@philipsacademync.org">
                info@philipsacademync.org
              </a>
            </p>
          </div>
        </div>
        <div>
          <div className="legal-notes">
            <p>
              <strong>NOTICE OF NONDISCRIMINATORY POLICY AS TO STUDENTS</strong>
            </p>
            <p>
              Philips Academy of North Carolina admits students of any race,
              color, national and ethnic origin to all the rights, privileges,
              programs, and activities generally accorded or made available to
              students at the school. It does not discriminate on the basis of
              race, color, national and ethnic origin in administration of its
              educational policies, admissions policies, scholarship and loan
              programs, and athletic and other school-administered programs.
            </p>
          </div>
        </div>
        <div className="caption-grid">
          <div>
            <p className="caption">
              © Copyright {year} · All Rights Reserved · Philips Academy of
              Charlotte, NC
            </p>
          </div>
          <div>
            <a
              href="https://wavefoundry.io"
              rel="noopener noreferrer"
              target="_blank"
              className="wf-logo"
            >
              <div className="icon">
                <WavefoundryLogo />
              </div>
              <div>Powered by Wavefoundry</div>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
