import React from 'react'
import Icon from "../components/Icon"

const footerLinks = [
    {
      href: "https://www.facebook.com/PhilipsAcademy/",
      iconName: "facebook",
      width: 26,
      title: "Follow us on Facebook"
    },
    {
      href: "https://www.instagram.com/philipsacademy/",
      iconName: "instagram",
      width: 28,
      title: "Follow us on Instagram"
    },
    {
      href: "https://twitter.com/philipsacadnc/",
      iconName: "twitter",
      width: 24,
      title: "Follow us on Twitter"
    }
  ]

export default ({className=''}) => {
    return (
        <div className='footer-btns'>
            {footerLinks.map(({ href, iconName, width, title }) => <a title={title} className={className} href={href} key={`footer-${iconName}`} target="_blank" rel="noopener noreferrer" aria-label={title}><Icon width={width} iconName={iconName} /></a>)}
        </div>
    )
}