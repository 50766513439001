import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";
import Header from "./Header";
import Footer from "./Footer";
import latoBold from "../../static/fonts/Lato-Bold.ttf";
import latoBoldItalic from "../../static/fonts/Lato-BoldItalic.ttf";
import latoItalic from "../../static/fonts/Lato-Italic.ttf";
import latoRegular from "../../static/fonts/Lato-Regular.ttf";
import "../stylesheets/index.css";
import "../stylesheets/layout.sass";

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]');
}

if (typeof window !== "undefined") {
}

const defaultTitle = "Philips Academy";
const defaultDescription = `Philips Academy is a community of learning unlike any other in Charlotte, NC. Designed specifically for students requiring a life-centered approach to education, our program offers a unique combination of remediation, practical instruction and community-based application.`;
const author = defaultTitle;

const Layout = ({ pageTitle, children, ogUrl, pageDecription, bodyOnly }) => {
  const calculatedTitle = `${defaultTitle} - ${pageTitle}`;
  const calculatedDescription = pageDecription || defaultDescription;

  useEffect(() => {
    if (window.Clym) {
      return;
    }

    const clymInitScript = document.createElement("script");
    clymInitScript.src = "https://widget.clym-sdk.net/blocking.js";

    const clymCustomScript = document.createElement("script");
    clymCustomScript.innerHTML = `(function(d,id,s,i,w,o){
var js,cjs=d.getElementsByTagName(s)[0],cs=d.getElementById(id);
if(cs){if(window.Clym) {return Clym&&Clym.load(i,w,o);}
var c=window._clymInit||[];c.push([i,w,o]);window._clymInit=c;return;}
js=d.createElement('script');
js.id=id;
js.src="https://widget.clym-sdk.net/clym.js";
js.onload=function(){Clym&&Clym.load(i,w,o);};
cjs.parentNode.insertBefore(js, cjs);
}(document,'clym-privacy','script','clym-privacy','5479b49858da4b8cae0ed34eekkg4trh',{}));`;

    document.body.appendChild(clymInitScript);
    document.body.appendChild(clymCustomScript);

    return () => {
      document.body.removeChild(clymInitScript);
      document.body.removeChild(clymCustomScript);
    };
  }, []);

  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>{calculatedTitle}</title>
        <meta name="description" content={calculatedDescription} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}media/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}media/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}media/favicon-16x16.png`}
          sizes="16x16"
        />
        <link
          rel="mask-icon"
          href={`${withPrefix("/")}media/safari-pinned-tab.svg`}
          color="#383481"
        />
        <link
          rel="preload"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
          href={latoRegular}
        />
        <link
          rel="preload"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
          href={latoItalic}
        />
        <link
          rel="preload"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
          href={latoBold}
        />
        <link
          rel="preload"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
          href={latoBoldItalic}
        />
        <meta name="theme-color" content="#ffffff" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={calculatedDescription} />
        <meta property="og:title" content={calculatedTitle} />
        <meta property="og:url" content={ogUrl} />
        <meta
          property="og:image"
          content={`${withPrefix("/")}media/og-image.jpg`}
        />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:creator" content={author} />
        <meta property="twitter:title" content={calculatedTitle} />
        <meta property="twitter:description" content={calculatedDescription} />
      </Helmet>
      {!bodyOnly && <Header />}
      {bodyOnly ? children : <main>{children}</main>}
      {!bodyOnly && <Footer />}
    </>
  );
};

Layout.propTypes = {
  pageTitle: PropTypes.string,
};

export default Layout;
