import React from "react";
import { Link } from "gatsby";
import Drawer from "@material-ui/core/Drawer";
import FooterButtons from "./FooterButtons";
import DropdownMenuLink from "./DropdownMenuLink";
import logo from "../images/logo.png";

const menuItems = [
  {
    to: "/about/",
    label: "ABOUT US",
    dropdownMenuItems: [
      {
        dropdownLabel: "OUR MISSION",
        dropdownTo: "/about/",
      },
      {
        dropdownLabel: "MEET THE TEAM",
        dropdownTo: "/about/#meet-the-team",
      },
      {
        dropdownLabel: "BOARD & SCHOOL HISTORY",
        dropdownTo: "/about/#board-and-history",
      },
      {
        dropdownLabel: "SCHOOL NEWSLETTERS",
        dropdownTo: "/about/#newsletters",
      },
      {
        dropdownLabel: "EMPLOYMENT OPPORTUNITIES",
        dropdownTo: "/about/#employment-opportunities",
      },
    ],
  },
  {
    to: "/program/",
    label: "PROGRAM",
    dropdownMenuItems: [
      {
        dropdownLabel: "PROGRAM OVERVIEW",
        dropdownTo: "/program/",
      },
      {
        dropdownLabel: "MIDDLE SCHOOL & HIGH SCHOOL",
        dropdownTo: "/program/#middle-school-and-high-school",
      },
      {
        dropdownLabel: "BRIDGES TRANSITION PROGRAM",
        dropdownTo: "/program/#bridges-transition-program",
      },
    ],
  },
  {
    to: "/admissions/",
    label: "ADMISSIONS",
    dropdownMenuItems: [
      {
        dropdownLabel: "ADMISSIONS OVERVIEW",
        dropdownTo: "/admissions/",
      },
      {
        dropdownLabel: "HOW TO APPLY",
        dropdownTo: "/admissions/#how-to-apply",
      },
      {
        dropdownLabel: "OPEN HOUSES",
        dropdownTo: "/admissions/#open-houses",
      },
    ],
  },
  {
    to: "/support/",
    label: "SUPPORT US",
    dropdownMenuItems: [
      {
        dropdownLabel: "HOW TO HELP",
        dropdownTo: "/support/",
      },
      {
        dropdownLabel: "SCHOLARSHIP ENDOWMENT",
        dropdownTo: "/scholarship-endowment/",
      },
      {
        dropdownLabel: "PLANNED GIVING",
        dropdownTo: "https://philipsacademync.plannedgiving.org",
      },
      {
        dropdownLabel: "SCHOOL SUPPORTERS",
        dropdownTo: "/supporters/",
      },
      {
        dropdownLabel: "PAVING THE WAY",
        dropdownTo: "/paving-the-way/",
      },
    ],
  },
  {
    to: "/calendar/",
    label: "CALENDAR",
  },
];

const Header = () => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const headerRef = React.useRef();
  const SCROLL_LIMIT = 50;
  const updateHeader = () => {
    if (
      document.body.scrollTop > SCROLL_LIMIT ||
      document.documentElement.scrollTop > SCROLL_LIMIT
    ) {
      headerRef.current.className = "header smaller";
    } else {
      headerRef.current.className = "header";
    }
  };
  const handleClose = () => setMenuOpen(false);
  React.useEffect(() => {
    window.addEventListener("scroll", updateHeader);
    return () => {
      window.removeEventListener("scroll", updateHeader);
    };
  }, []);

  return (
    <>
      <Drawer anchor="right" open={menuOpen} onClose={handleClose}>
        <div className="menu-drawer text-center">
          <div>
            <div className="logo-container">
              <Link
                to="/"
                title="Philips Academy"
                className="logo"
                onClick={handleClose}
              >
                <img alt="Philips Academy Logo" src={logo}></img>
              </Link>
            </div>
            <ul>
              {menuItems.map(({ to, label }) => {
                return (
                  <li key={`drawer-${label}`} className="drawer-menu-item">
                    <Link to={to} onClick={handleClose}>
                      {label}
                    </Link>
                  </li>
                );
              })}
              <li className="drawer-menu-item">
                <Link
                  to="/donate/"
                  className="btn donate-btn"
                  onClick={handleClose}
                  style={{ width: 180 }}
                >
                  DONATE
                </Link>
              </li>
              <li className="drawer-menu-item">
                <Link
                  to="/contact/"
                  className="btn contact-btn"
                  onClick={handleClose}
                  style={{ width: 180 }}
                >
                  CONTACT US
                </Link>
              </li>
            </ul>
          </div>
          <FooterButtons />
        </div>
      </Drawer>
      <header className="header" ref={headerRef}>
        <div className="layout-container">
          <div className="header-container">
            <Link to="/" title="Philips Academy" className="logo">
              <img alt="Philips Academy Logo" src={logo}></img>
            </Link>
            <div className="display-flex">
              <div className="header-menu-items">
                <ul className="menu-items">
                  {menuItems.map((itemProps) => {
                    if (itemProps.dropdownMenuItems) {
                      return (
                        <DropdownMenuLink
                          {...itemProps}
                          key={itemProps.label}
                        />
                      );
                    }
                    return (
                      <li key={itemProps.label}>
                        <Link
                          className="menu-link"
                          to={itemProps.to}
                          activeClassName="active"
                        >
                          {itemProps.label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div>
                <Link
                  to="/donate/"
                  className="btn donate-btn header-filled-btn"
                  activeClassName="active"
                >
                  DONATE
                </Link>
              </div>
              <div>
                <Link
                  to="/contact/"
                  className="btn contact-btn header-filled-btn"
                  activeClassName="active"
                >
                  CONTACT US
                </Link>
              </div>
              <div className="menu-icon">
                <button className="btn" onClick={() => setMenuOpen(true)}>
                  <div className="hamburger">
                    <span />
                    <span />
                    <span />
                  </div>
                  <span>MENU</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
